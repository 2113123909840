import { Box, Button, Checkbox, Container, Divider, FormControl, FormLabel, Input, Stack, Text, Tooltip } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./pages.scss";
import { useSelector } from "react-redux";
import { baseApiParams, Api, HttpResponse, UserDto } from 'my-api-client-package';

import { Notification } from "../../../helpers/Notification";
import { CONSTANTS } from "../../../constants";
import { useDispatch } from "react-redux";
import HyphenatedText from "../../../helpers/HyphenatedText";

export const Payments = () => {
    const { t } = useTranslation();
    const userObj = useSelector((state: any) => state.user);
    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    const [alertMessage, setAlertMessage] = useState<string | null>(null)
    const [alertHead, setAlertHead] = useState<string>('')
    const [alertType, setAlertType] = useState<string>('success')
    const dispatch = useDispatch();


    const [formValues, setFormValues] = useState({
        title: userObj.titel || "",
        firstName: userObj.firstname || "",
        lastName: userObj.lastname || "",
        paymentAllowed: userObj.paymentAllowed || false,

        accountFirstName: userObj.firstname || "",
        accountLastName: userObj.lastname || "",

        street: "",
        location: "",
        postalCode: "",
        iban: "",
        bank: "",
        bic: "",
    });

    const [isAddressSectionModified, setIsAddressSectionModified] = useState(false);
    const [isAccountSectionModified, setIsAccountSectionModified] = useState(false);

    // Fetch data from the database
    useEffect(() => {
        const fetchInitialData = async () => {
            const userId = userObj.id.id;

            try {
                const userInfo = await api.user.getUserInfos({ userId }, baseApiParams()) as HttpResponse<any, any>;
                console.log(userInfo.data)
                setFormValues({
                    ...formValues,
                    iban: userInfo.data.iban || "",
                    bank: userInfo.data.bank || "",
                    bic: userInfo.data.bic || "",
                    location: userInfo.data.location || "",
                    street: userInfo.data.street || "",
                    postalCode: userInfo.data.zipCode || "",
                });
            } catch (error) {
                console.error(t("messages.error"), error);
            }
        };

        fetchInitialData();
    }, []);

    const handleInputChange = (e: any) => {
        const { name, type, value, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value,
        }));

        if (["title", "firstName", "lastName", "street", "location", "postalCode", "paymenAllowed"].includes(name) && !isAddressSectionModified) {
            setIsAddressSectionModified(true);
        }

        if (["accountFirstName", "accountLastName", "iban", "bank", "bic"].includes(name) && !isAccountSectionModified) {
            setIsAccountSectionModified(true);
        }
    };

    const handleAddressInfo = async () => {
        handlePaymentInfo()

        const userAddressInfo: UserDto = {
            id: userObj.id,
            email: userObj.email,
            titel: formValues.title,
            firstname: formValues.firstName,
            lastname: formValues.lastName,
            paymentAllowed: formValues.paymentAllowed
        }
        console.log(userAddressInfo)
        try {
            const resp = await api.user.saveUser(userAddressInfo, baseApiParams())
            console.log(resp.data)
            dispatch({ type: CONSTANTS.CHANGE_USER, payload: resp.data });
            setAlertType('success')
            setAlertMessage(t("messages.information_updated_successfully"))
        }
        catch (e) {
            console.log(e)
            setAlertType('error')
            setAlertMessage(t("messages.error"))
        }

    }

    const handlePaymentInfo = async () => {
        const bankInfo = {
            iban: formValues.iban,
            bic: formValues.bic,
            bank: formValues.bank,
            type: "bank",
            location: formValues.location,
            street: formValues.street,
            zipCode: formValues.postalCode,
        }
        console.log(bankInfo)
        try {
            const resp = await api.user.addPaymentInfo(bankInfo, baseApiParams()) as HttpResponse<any, any>;
            console.log(resp)
            setAlertType('success')
            setAlertMessage(t("messages.information_updated_successfully"))
        }
        catch (e) {
            console.log(e)
            setAlertType('error')
            setAlertMessage(t("messages.error"))
        }
    }

    return (
        <>
            <Text className="page-heading">
                {t('rate_my.payments')}
                <Tooltip hasArrow label={t("rate_my.edit_your_payment_details")} bg='#0C111D' color='#D0D5DD' borderRadius={"8px"} padding={"10px"}>
                    <InfoOutlineIcon color={"#8C8C8C"} />
                </Tooltip>
            </Text>
            <Box bg={"#FFF"} padding={'0'} margin={'0'} borderRadius={'8px'} paddingRight={'15px'} paddingLeft={'15px'} paddingBottom={"20px"}>
                <Stack padding={"20px 0"}>
                    <Checkbox
                        padding="12px 0"
                        name="paymentAllowed"
                        isChecked={formValues.paymentAllowed}
                        onChange={handleInputChange}
                    >
                        {t('rate_my.i_dont_want_to')}
                    </Checkbox>
                </Stack>

                {/* Address Section */}
                <Stack flexDirection={{ base: "column", md: "row" }} gap={{ base: "0", md: "30px" }}>
                    <Box width={{ base: "100%", md: "85px" }}>
                        <Text className="payment-info-heading">{t('rate_my.address')}</Text>
                        <Text className="payment-info-Sub">{t('rate_my.your_billing_address_here')}</Text>
                    </Box>
                    <Box width={"100%"} className="payment-form-block" gap={"15px"}>
                        <Stack padding={{ base: "0", md: "24px" }} gap={{ base: "15px", md: "10px" }}>
                            <Container display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "92px" }} margin={"0"} width={"100%"} maxW={"auto"} paddingInlineEnd={"0"}>
                                <Stack display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                    <FormControl minW={"155px"}>
                                        <FormLabel className="input-label">{t('rate_my.title')}</FormLabel>
                                        <Input name="title" type='text' className="payment-input-input" value={formValues.title} onChange={handleInputChange} />
                                    </FormControl>
                                    <FormControl minW={"155px"}>
                                        <FormLabel className="input-label">{t('rate_my.first_name')}</FormLabel>
                                        <Input name="firstName" type='text' className="payment-input-input" value={formValues.firstName} onChange={handleInputChange} />
                                    </FormControl>
                                </Stack>

                                <Stack display={"flex"} flexDirection={"row"} gap={{ base: "15px", md: "10px" }} padding={"0"} width={"100%"}>
                                    <FormControl>
                                        <FormLabel className="input-label">{t('general.last_name')}</FormLabel>
                                        <Input name="lastName" type='text' className="payment-input-input" value={formValues.lastName} onChange={handleInputChange} />
                                    </FormControl>
                                </Stack>
                            </Container>

                            <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                                <FormControl>
                                    <FormLabel className="input-label">{t('rate_my.street_house_number')}</FormLabel>
                                    <Input name="street" type='text' className="payment-input-input" value={formValues.street} onChange={handleInputChange} />
                                </FormControl>
                            </Container>

                            <Container width={"100%"} margin={"0"} maxW={'auto'} flexDirection={{ base: "column", md: "row" }} display={'flex'} gap={{ base: "15px", md: "10px" }} paddingInlineEnd={"0"}>
                                <FormControl maxW={{ base: "100%", md: "50%" }}>
                                    <FormLabel className="input-label">{t('rate_my.location')}</FormLabel>
                                    <Input name="location" type='text' className="payment-input-input" value={formValues.location} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl maxW={{ base: "100%", md: "50%" }}>
                                    <FormLabel className="input-label">{t('rate_my.postal_code')}</FormLabel>
                                    <Input name="postalCode" type='text' className="payment-input-input" value={formValues.postalCode} onChange={handleInputChange} />
                                </FormControl>
                            </Container>
                        </Stack>

                        <Divider />
                        <Stack direction='row' alignItems={'center'} justifyContent={{ base: 'center', md: 'end' }} padding={{ base: "0 0 20px 0", md: "16px 25px 16px 0" }}>
                            <Button className="save-btn" color={'#fff'} isDisabled={!isAddressSectionModified} onClick={() => handleAddressInfo()}>
                                {t('rate_my.save')}
                            </Button>
                        </Stack>

                    </Box>
                </Stack>

                {/* Account Information Section */}
                <Stack flexDirection={{ base: "column", md: "row" }} gap={{ base: "0", md: "30px" }}>
                    <Box width={{ base: "100%", md: "85px" }}>
                        <HyphenatedText text={t('rate_my.account_information')} className="payment-info-heading" language="de" />
                        <Text className="payment-info-Sub">{t('rate_my.enter_your_account_information')}</Text>
                    </Box>
                    <Box width={"100%"} className="payment-form-block" gap={{ base: "10px", md: "10px" }}>
                        <Stack padding={{ base: "0", md: "24px" }} gap={{ base: "15px", md: "10px" }}>
                            <Container display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "10px", md: "10px" }} margin={"0"} width={"100%"} maxW={"auto"} paddingInlineEnd={"0"}>
                                <FormControl minW={"155px"}>
                                    <FormLabel className="input-label">{t('rate_my.first_name')}</FormLabel>
                                    <Input name="accountFirstName" type='text' className="payment-input-input" value={formValues.accountFirstName} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel className="input-label">{t('general.last_name')}</FormLabel>
                                    <Input name="accountLastName" type='text' className="payment-input-input" value={formValues.accountLastName} onChange={handleInputChange} />
                                </FormControl>
                            </Container>

                            <Container width={"100%"} margin={"0"} paddingInlineEnd={"0"}>
                                <FormControl>
                                    <FormLabel className="input-label">{t('rate_my.iban')}</FormLabel>
                                    <Input name="iban" type='text' className="payment-input-input font-variant-none" value={formValues.iban} onChange={handleInputChange} />
                                </FormControl>
                            </Container>

                            <Container width={"100%"} margin={"0"} maxW={'auto'} flexDirection={{ base: "column", md: "row" }} display={'flex'} gap={{ base: "15px", md: "10px" }} paddingInlineEnd={"0"}>
                                <FormControl maxW={{ base: "100%", md: "50%" }}>
                                    <FormLabel className="input-label">{t('rate_my.bank')}</FormLabel>
                                    <Input name="bank" type='text' className="payment-input-input" value={formValues.bank} onChange={handleInputChange} />
                                </FormControl>
                                <FormControl maxW={{ base: "100%", md: "50%" }}>
                                    <FormLabel className="input-label">{t('rate_my.bic')}</FormLabel>
                                    <Input name="bic" type='text' className="payment-input-input" value={formValues.bic} onChange={handleInputChange} />
                                </FormControl>
                            </Container>
                        </Stack>
                        <Divider />
                        <Stack direction='row' alignItems={'center'} justifyContent={{ base: 'center', md: 'end' }} padding={{ base: "0", md: "16px 25px 16px 0" }}>
                            <Button className="save-btn" color={'#fff'} isDisabled={!isAccountSectionModified} onClick={() => handlePaymentInfo()}>
                                {t('rate_my.save')}
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            {alertMessage && <Notification heading={alertHead} message={alertMessage} type={alertType} buttonText="Skip" onClose={() => setAlertMessage(null)} />}
        </>
    );
};