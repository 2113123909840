import {
  Box,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import Logo from '../../assets/img/logo_mediMESH_weis_large.svg';
import bg from '../../assets/img/Hero2.svg';
import { RegisterForm } from 'auth-components-package/dist';

import './Register.scss';
import { useTranslation } from 'react-i18next';
export const Register = () => {
  const { t } = useTranslation();
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY || '';
  const API_URL = process.env.REACT_APP_API_URL || '';

  return (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={{
        md: 'linear(to-r, bg.accent.default 50%, bg.surface 50%)',
      }}
      className='register-container'
      marginTop={{ base: "100px", md: "0px" }}

    >
      <Flex maxW="full" mx="auto" width="full">
        <Box flex="1" display={{ base: 'none', md: 'block' }} style={{ flex: '60%' }}>
          <Flex
            direction="column"
            px={{ base: '4', md: '8' }}
            height="full"
            color="fg.accent.default"
            style={{ backgroundImage: `url(${bg})` }}
            className='login-left-side'
          >
            <Flex align="center" h="24" paddingLeft={"50px"} py={130}>
              <img src={Logo} alt='mediMESH' />
            </Flex>
            <Flex flex="1" flexDirection={'column'} paddingLeft={"50px"}>
              <Stack spacing="6">
                <Heading width={'100%'} className='heading'>{t("rate_my.welcome_to_the_medimesh")} 👋</Heading>
                <Text className='sub-text'>
                  {t("rate_my.contribute_your_expertise")}
                </Text>
              </Stack>
            </Flex>
          </Flex>
        </Box>
        <Center flex="1" style={{ flex: '40%' }}>

          <RegisterForm
            px={{ base: '4', md: '8' }}
            width="full"
            maxW="md"

            reCaptchaKey={reCaptchaKey}
            t={t}
            lastLogin="ratemy"
            apiURL={API_URL}
          />
        </Center>
      </Flex>
    </Flex>
  )
}