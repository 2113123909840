import { Card, Stack, Text, Divider } from "@chakra-ui/react"
import "./pages.scss"
import { useTranslation } from "react-i18next"
export const Help = () => {
    const { t } = useTranslation();

    return (
        <>
            <Text className="page-heading">{t("rate_my.help")}</Text>
            <Card width="inherit" padding={"20px 15px 20px 15px"} boxShadow={"none"}>
                <Text className="help-small-text" padding={"0px 0px 15px 0px"}>{t("rate_my.info_for_employer")}</Text>
                <Text className="help-big-text">{t("rate_my.work_in_healthcare_sector")}</Text>
                <Text className="help-small-text">{t("rate_my.work_in_healthcare_sector_details")}</Text>

                <Text className="help-big-text" padding={"15px 0px 10px 0px"}>{t("rate_my.tax_obligation")}</Text>
                <Text className="help-small-text" padding={"0px 0px 12px 0px"}>{t("rate_my.tax_obligation_details")}</Text>

                <Stack className="tax-info">
                    <Stack>
                        <Text className="help-big-text">{t("rate_my.tax_obligation_one")}</Text>
                        <Text className="tax-info-text">{t("rate_my.tax_obligation_one_details")}</Text>
                    </Stack>
                    <Divider />
                    <Stack>
                        <Text className="help-big-text">{t("rate_my.tax_obligation_two")}</Text>
                        <Text className="tax-info-text">{t("rate_my.tax_obligation_two_details")}</Text>
                    </Stack>
                    <Divider />
                    <Stack>
                        <Text className="help-big-text">{t("rate_my.tax_obligation_three")}</Text>
                        <Text className="tax-info-text">{t("rate_my.tax_obligation_three_details")}</Text>
                    </Stack>
                </Stack>

                <Text className="help-big-text" padding={"15px 0px 10px 0px"}>{t("rate_my.notification_of_secondary_employment")}</Text>
                <Text className="help-small-text">{t("rate_my.notification_of_secondary_employment_details")}</Text>
                <Text className="help-big-text" padding={"15px 0px 10px 0px"}>{t("rate_my.help_page_disclaimer")}</Text>
                <Text className="help-small-text">{t("rate_my.help_page_disclaimer_details")}</Text>

            </Card>
        </>
    )
}