import { Avatar, Box, IconButton } from "@chakra-ui/react"
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { FiLogOut } from "react-icons/fi"
import { setActivePage } from "../../../actions"
import { useDispatch } from "react-redux"
import { removeAuthAccessObject } from "my-api-client-package"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"


export const AvatarMenu = () => {
    const userObj = useSelector((state: any) => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = () => {
        removeAuthAccessObject();
        navigate("/login");
    };

    return (
        <Box className="avatar-menu">
            <Menu>
                <MenuButton
                    as={Avatar}
                    aria-label='Options'
                    icon={userObj && <Avatar as={IconButton} aria-label='Options' boxSize="10" src={userObj.avatar} />}
                    variant='outline'
                    boxSize="10"
                />
                <MenuList minWidth={"145px"} className="avatar-menu-list">
                    <MenuItem className="profile-menu-item" onClick={() => dispatch(setActivePage("Profile"))} fontSize={"14px"} icon={<Avatar padding={0} margin={0} size="sm" src={userObj.avatar} />}>
                        {t('rate_my.profile')}
                    </MenuItem>
                    <MenuItem className="profile-menu-item" onClick={() => logout()} paddingLeft={"20px"} fontSize={"14px"} icon={<FiLogOut color={"#A0AEC0"} size={24} />}>
                        {t('general.logout')}

                    </MenuItem>

                </MenuList>
            </Menu>
        </Box>
    )
}