import { Card, Heading, Stack, Text, Textarea, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Notification } from "../../../helpers/Notification";
import { Api, baseApiParams } from "my-api-client-package";

import "./pages.scss";

export const Contact = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const [alert, setAlert] = useState({
        type: "success",
        heading: "",
        message: null as string | null,
    });

    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    const handleInputChange = (value: string) => {
        setMessage(value);
        setIsButtonDisabled(!value.trim());
    };

    const handleSubmit = async () => {
        setIsButtonDisabled(true)
        try {
            const res = await api.user.sendContactEmail(message, baseApiParams());
            if (res.status === 200) {
                setAlert({
                    type: "success",
                    heading: t("rate_my.message_sent_successfully"),
                    message: "",
                });
                setMessage("");
            } else {
                throw new Error(t("rate_my.failed_to_send_message"));
            }
        } catch (error) {
            setAlert({
                type: "error",
                heading: t("general.error"),
                message: t("rate_my.failed_to_send_message"),
            });
        }
    };

    return (
        <>
            <Card className="contact-card" boxShadow={"none"} padding={{ base: "15px", md: "30px" }} alignItems={"center"}>
                <Stack className="contact-form" gap={{ base: "10px", md: "30px" }}>
                    <Heading className="contact-heading">
                        {t("rate_my.get_in_touch_with_us")}
                    </Heading>
                    <Text className="contact-hint">{t("rate_my.we_will_get_back")}</Text>

                    <Stack className="user-info-contact" gap={{ base: "10px", md: "30px" }}>
                        <Textarea
                            placeholder={t("rate_my.write_to_us")}
                            value={message}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </Stack>

                    <Button
                        className="save-btn-"
                        background="#319795"
                        color="white"
                        isDisabled={isButtonDisabled}
                        onClick={handleSubmit}
                        paddingInlineStart={'unset'}
                        paddingInlineEnd={'unset'}
                    >
                        {t("rate_my.confirm_btn")}
                    </Button>
                </Stack>
            </Card>

            {alert.message || alert.heading && (
                <Notification
                    heading={alert.heading}
                    message={alert.message}
                    type={alert.type}
                    buttonText={t("smartslide.close_make_video_done")}
                    onClose={() => setAlert({ ...alert, message: null })}
                />
            )}
        </>
    );
};