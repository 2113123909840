import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import './Dashboard.scss';
import { Sidebar } from './Sidebar';
import logo from "../../assets/img/logo_mediMESH_only_small.svg";
import { Studies } from './pages/Studies';
import { History } from './pages/History';
import { Payments } from './pages/Payments';
import { Profile } from './pages/Profile';
import { Help } from './pages/Help';
import { Contact } from './pages/Contact';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CONSTANTS } from '../../constants.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { loadUserInfo } from '../../helpers/loadUserInfo';
import { IdleRefreshComponent } from 'auth-components-package/dist';
import { AvatarMenu } from './pages/AvatarMenu';

function Dashboard() {
  const { t } = useTranslation();
  const activePage = useSelector((state: any) => state.activePage);
  const userObj = useSelector((state: any) => state.user);
  const token = useSelector((state: any) => state.token);
  const API_URL = process.env.REACT_APP_API_URL || '';
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderActivePage = () => {
    switch (activePage) {
      case 'Studies':
        return <Studies />;
      case 'History':
        return <History />;
      case 'Payments':
        return <Payments />;
      case 'Profile':
        return <Profile />;
      case 'Help':
        return <Help />;
      case 'Contact':
        return <Contact />;
      default:
        return <Studies />;
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userObj.email) loadUserInfo(token, dispatch, navigate);
  }, [token]);

  return (
    <Box minH="100vh">
      <IdleRefreshComponent
        token={CONSTANTS.CHANGE_TOKEN}
        dispatch={dispatch}
        navigate={navigate}
        t={t}
        apiURL={API_URL}
        idleTimeout={1200000}
        refrestTimeout={600000}
      />
      <HStack
        flex="1"
        maxW={{ base: 'full' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
        minH={"10vh"}
        boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"}
        position={"fixed"}
        backgroundColor={"#FFF"}
        justifyContent={"space-between"}
        width={"100%"}
        zIndex={"9"}
        className='my-header'
      >
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          display={{ base: 'block', lg: 'none' }}
        />
        <img src={logo} alt="mediMESH" width={"100px"} />
        {userObj && <AvatarMenu />}
      </HStack>
      <Flex as="section" minH="90vh">
        {/* Sidebar for larger screens */}
        <Box display={{ base: 'none', lg: 'block' }}>
          <Sidebar userObj={userObj} />
        </Box>

        {/* Drawer for smaller screens */}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent className='my-drawer-content'>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody className='my-drawer-body'>
              <Sidebar userObj={userObj} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Main content */}
        <Box className='my-content-body' minH={"100vh"} backgroundColor={"#eef2f6"} minWidth={"0"}
        >
          <Stack flex="1"
            paddingTop={{ base: "20px", sm: "20px" }}
            paddingBottom={{ base: "20px", sm: "20px" }}
            paddingLeft={{ base: "10px", sm: "20px" }}
            paddingRight={{ base: "10px", sm: "20px" }}
            bg="#eef2f6"
            borderRightWidth="1px"
            marginTop={{ base: "80px", sm: "120px" }}
            minWidth={"0"}
          >
            {renderActivePage()}
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

export default Dashboard;