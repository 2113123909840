// src/components/HyphenatedText.tsx
import React, { useEffect, useState } from "react";
import { hyphenateText } from "./hyphenate"; // Import the utility
import { Text } from "@chakra-ui/react";

// Define the props for the component
interface HyphenatedTextProps {
    text: string;
    language?: "en" | "de"; // Restrict language to supported values
    className?: string;
}

const HyphenatedText: React.FC<HyphenatedTextProps> = ({ text, language = "de", className }) => {
    const [hyphenatedText, setHyphenatedText] = useState<string>(text);

    useEffect(() => {
        // Validate the input text
        if (typeof text !== "string") {
            console.warn("Invalid text provided to HyphenatedText. Expected a string.");
            return;
        }

        // Hyphenate the text asynchronously
        const hyphenate = async () => {
            try {
                const result = await hyphenateText(text, language);
                setHyphenatedText(result);
            } catch (error) {
                console.error("Hyphenation failed:", error);
                setHyphenatedText(text); // Fallback to the original text if hyphenation fails
            }
        };

        hyphenate();
    }, [text, language]);

    return (
        <Text className={className} style={{ hyphens: "auto", overflowWrap: "break-word" }}>
            {hyphenatedText}
        </Text>
    );
};

export default HyphenatedText;