// src/utils/hyphenate.ts
import { hyphenate as hyphenateEn } from "hyphen/en"; 
import { hyphenate as hyphenateDe } from "hyphen/de"; 

// Define a type for supported languages
type Language = "en" | "de";

// Map language codes to their respective hyphenation functions
const hyphenators: Record<Language, (text: string) => Promise<string>> = {
  en: hyphenateEn,
  de: hyphenateDe,
};

export const hyphenateText = async (text: string, language: Language = "en"): Promise<string> => {
  const hyphenator = hyphenators[language]; // Get the hyphenator function for the specified language
  if (!hyphenator) {
    console.warn(`Hyphenator for language "${language}" not found. Using English as fallback.`);
    return text; // Fallback to no hyphenation if the hyphenator is not found
  }

  try {
    const hyphenatedText = await hyphenator(text); // Hyphenate the text asynchronously
    return hyphenatedText;
  } catch (error) {
    console.error("Hyphenation failed:", error);
    return text; // Fallback to the original text if hyphenation fails
  }
};