import { useState } from "react";

import {
    Tooltip,
    Box,
    Button,
    Stack,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Input,
    Radio,
    RadioGroup
} from "@chakra-ui/react"
import "./pages.scss"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next";
import { HistoryPaymentTable } from "./HistoryPaymentTable";
import { IoArrowForwardOutline } from "react-icons/io5";
import { baseApiParams, Api, Feedback } from 'my-api-client-package';
import { Notification } from "../../../helpers/Notification";

export const History = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [feedbackRating, setFeedbackRating] = useState('5')
    const [feedbackSuggestion, setFeedbackSuggestion] = useState('')
    const [selectedStudy, setSelectedStudy] = useState(Object)

    const [alertMessage, setAlertMessage] = useState<string | null>(null)
    const [alertHead, setAlertHead] = useState<string>('')
    const [alertType, setAlertType] = useState<string>('success')

    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    const handleSuggestionChange = (event: any) => {
        setFeedbackSuggestion(event.target.value)
    }

    const handleFeedback = () => {

        const feedback: Feedback = {
            rating: parseInt(feedbackRating),
            additionalInfo: feedbackSuggestion,
            user: selectedStudy.activity.user
        }

        try {
            api.activity.sendActivityFeedback(selectedStudy.activity.id, feedback, baseApiParams()).then((respons: any) => {
                onClose()
                setAlertType('success')
                setAlertMessage(respons.data.message)
            })
        }
        catch (e) {
            setAlertType('error')
            setAlertMessage(t("messages.error"))
        }

    }

    const handleOpenFeedback = (study: object) => {
        setSelectedStudy(study)
        onOpen()
    }
    return (
        <>
            <Text className="page-heading" display={"flex"} gap={"12px"} alignItems={"center"} height={"32px"}>
                {t("rate_my.history")}
                <Tooltip hasArrow label={t("rate_my.consultations_payments_feedback_download")} bg='#0C111D' color='#D0D5DD' borderRadius={"8px"} padding={"10px"}>
                    <InfoOutlineIcon color={"#8C8C8C"} />
                </Tooltip>
            </Text>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent padding={"40px"} minW={'920px'}>
                    <ModalHeader padding={0} display="flex" flexDirection={'column'} gap="20px">
                        <Text className="history-feedback-titel">{t("rate_my.give_us_your_feedback")}</Text>
                        <Input
                            className="history-feedback-input"
                            placeholder={t("rate_my.do_you_have_any_suggestions")}
                            size='sm'
                            value={feedbackSuggestion}
                            onChange={handleSuggestionChange} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody padding={0} marginTop="30px" display="flex" flexDirection={'column'} gap="10px">
                        <Stack>
                            <Text className="history-feedback-subtext">{t("rate_my.please_give_us_feedback")}</Text>
                        </Stack>
                        <RadioGroup onChange={setFeedbackRating} value={feedbackRating}>
                            <Stack direction='column' gap="0" className="history-checkbox-block">
                                <Radio value="5">{t("rate_my.excellent")}</Radio>
                                <Radio value="4">{t("rate_my.very_good")}</Radio>
                                <Radio value="3">{t("rate_my.good")}</Radio>
                                <Radio value="2">{t("rate_my.satisfactory")}</Radio>
                                <Radio value="1">{t("rate_my.poor")}</Radio>
                            </Stack>
                        </RadioGroup>
                    </ModalBody>

                    <ModalFooter padding="0" marginTop="30px">
                        <Button minW="100%" bg="#319795" color="#fff" borderRadius="8px" onClick={() => handleFeedback()}>
                            {t("rate_my.send_feedback")}
                            <IoArrowForwardOutline />
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box overflowX="auto" width={"100%"}>
                <HistoryPaymentTable handleOnClick={handleOpenFeedback} />
            </Box>

            {alertMessage && <Notification heading={alertHead} message={alertMessage} type={alertType} buttonText="Skip" onClose={() => setAlertMessage(null)} />}

        </>
    )
}