import { useState, useEffect } from "react";

import {
    Badge,
    Button,
    Table,
    TableProps,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    HStack,
    TableContainer
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { IoAdd, IoDownloadOutline } from 'react-icons/io5'
import { baseApiParams, Api, Media, HttpResponse } from 'my-api-client-package';
import { useSelector } from "react-redux";
import moment from 'moment';
import 'moment/locale/de';

interface Props {
    handleOnClick: (study: any) => void;
}
export const HistoryPaymentTable = (props: Props) => {
    const { t } = useTranslation();

    const userObj = useSelector((state: any) => state.user);

    const [history, setHistory] = useState([]);

    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    useEffect(() => {
        const userId = userObj.id.id;

        api.paymenthistory.getUserPaymentHistory(userId, baseApiParams()).then((response: any) => {
            setHistory(response.data)
        })
    }, [])

    const handleDownload = async (documentID: string) => {
        try {
            const mediaData = await api.media.getMedia(documentID, baseApiParams()) as HttpResponse<void, Media>
            const dataType = mediaData.data.unsignedUrl.split(".").pop().toLowerCase();
            const mimeTypes = {
                pdf: "application/pdf",
                jpg: "image/jpeg",
                png: "image/png"
            };
            if (!mimeTypes[dataType]) {
                throw new Error(`Unsupported file type: ${dataType}`);
            }
            const response = await fetch(mediaData.data.url);
            const data = await response.arrayBuffer();
            const blob = new Blob([data], { type: mimeTypes[dataType] });
            const url = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.download = mediaData.data.unsignedUrl;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error.message);
        }
    };

    return (
        <TableContainer borderRadius="8px" maxWidth={"100%"} overflow={"auto"} whiteSpace={"nowrap"} className="table-container">
            <Table {...props} bg="#FFF">
                <Thead bg="#F7FAFC" className="table-heading">
                    <Tr className="table-heading-title">
                        <Th>Rate-my</Th>
                        <Th>{t("rate_my.rate_my_date")}</Th>
                        <Th>{t("rate_my.amount_status")}</Th>
                        <Th>{t("rate_my.invoice_details")}</Th>
                        <Th>{t("rate_my.feedback")}</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody className="table-body-content">
                    {(history && history.length > 0) ? history.map((el: any) => (
                        <Tr key={el.id}>
                            <Td>
                                <Text className="rate-my-cell">{el.study}</Text>
                            </Td>
                            <Td>
                                <Text className="history-table-date-cell">{moment(el.payedDate.replace(/\[.*\]$/, '')).locale('de').format('D. MMM YYYY')}</Text>
                            </Td>

                            <Td>
                                <HStack className="history-text-btn">
                                    <Text className="rate-my-cell">{el.payedAmount}</Text>
                                    {el.status && <Badge size="sm" className="success-badge">
                                        {el.status}
                                    </Badge>}
                                </HStack>
                            </Td>

                            <Td>
                                <Button colorScheme={"#050505"} variant="outline" onClick={() => handleDownload(el.document.id)} gap={"5px"}>
                                    <IoDownloadOutline /> <Text className="history-table-btn">{t("rate_my.invoice")}</Text>
                                </Button>
                            </Td>
                            <Td>
                                <Button colorScheme={"#050505"} variant="outline" onClick={() => props.handleOnClick(el)} gap={"5px"}>
                                    <IoAdd /> <Text className="history-table-btn">{t("rate_my.add")}</Text>
                                </Button>
                            </Td>
                        </Tr>
                    )) : <Badge variant='outline'>Keine Zahlungshistorie</Badge>}
                </Tbody>
            </Table >
        </TableContainer>
    )

}